<template>
    <div>
        <v-dialog v-model="openEditCategory"  width="800">
          <v-card class="pa-6">
                <h2>Управление категориями</h2>
                <div class="mb-6">
                    <div class="item__row item__ac" v-for="item in categories" :key="item.id">
                        <p class="mr-2 mb-0">{{item.name}}</p>

                        <i @click="show(item)" class="mdi mdi-lead-pencil"></i>
                        <i @click="deleteCategory(item.id)" class="mdi mdi-trash-can-outline"></i>
                    </div>
                </div>


                <h2 class="mb-4">Редактировать категорию</h2>
               
                <v-form
                    @submit.prevent="update()"
                    ref="form"
                    class="sign__page__block"
                >
                    <div class="item__column">
                        <v-text-field
                            v-model="category_name"
                            label="Название категорий"
                            required
                            outlined
                            class="input"
                        ></v-text-field>
                    </div>
                    <v-btn
                        type="submit"
                        depressed
                        color="primary"
                        class="mr-4"
                        >
                        Сохранить изменения
                    </v-btn>

                </v-form>
          </v-card>
        </v-dialog>
        <v-dialog v-model="openCategory"  width="800">
          <v-card class="pa-6">
                <h2>Управление категориями</h2>
                <div class="mb-6">
                    <div class="item__row item__ac" v-for="item in categories" :key="item.id">
                        <p class="mr-2 mb-0">{{item.name}}</p>

                        <i @click="show(item)" class="mdi mdi-lead-pencil"></i>
                        <i @click="deleteCategory(item.id)" class="mdi mdi-trash-can-outline"></i>
                    </div>
                </div>


                <h2 class="mb-4">Добавить категорию</h2>
               
                <v-form
                    @submit.prevent="selectFunction()"
                    ref="form"
                    class="sign__page__block"
                >
                    <div class="item__column">
                        <v-text-field
                            v-model="category_name"
                            label="Название категорий"
                            required
                            outlined
                            class="input"
                        ></v-text-field>
                    </div>
                    <v-btn
                        type="submit"
                        depressed
                        color="primary"
                        class="mr-4"
                        >
                        Сохранить изменения
                    </v-btn>

                </v-form>
          </v-card>
        </v-dialog>

    </div>
</template>


<script>
export default {
  props: [
    'resultC'
  ],
  name: "News",
  data() {
    return {
        categories: [],
        category_name: '',
        openCategory: false,
        selectedId: '',
        type: 1,
        openEditCategory: false
    };
  },
  methods: {
        selectFunction() {
            this.type==1?this.create():this.update();
        },
        show(item) {
            this.openEditCategory = true;
            this.openCategory  = false;
            this.type=2;
            this.category_name = item.name;
            this.selectedId = item.id;
        },
        showMessage(type,text) {
             this.$toast.open({
                message: text,
                type: type,
                position: "bottom",
                duration: 4000,
                queue: true,
            });
        },
        deleteCategory(id) {
            this.$axios
                .delete(this.$API_URL + this.$API_VERSION + "category/"+id, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`
                },
            })
            .then((response) => {
                this.getCategory();
                this.openCategory = false;
                this.showMessage('success',response.data.message);
            })
            .catch((error) => {
                if (error.response && error.response.status == 422) {
                   this.showMessage('error',"Не правильно заполнено");
                }
            });
        },
        getCategory() {
            this.$axios
                .get(this.$API_URL + this.$API_VERSION + "category?type="+this.$route.query.type, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`
                },
            })
            .then((response) => {
                this.categories = response.data;
            })
            .catch((error) => {
                if (error.response && error.response.status == 422) {
                   this.showMessage('error',"Не правильно заполнено");
                }
            });
        },  
        create() {
            let contractForm = new FormData();
            contractForm.append("type", this.$route.query.type);
            contractForm.append("name", this.category_name);
            this.$axios
                .post(this.$API_URL + this.$API_VERSION + "category", contractForm, {
                headers: {
                    
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                this.openCategory = false;
                this.showMessage('success',response.data.message);
                this.fetch(this.options);
                this.getCategory();
                this.category_name = '';
            })
            .catch((error) => {
                if (error.response && error.response.status == 422) {
                   this.showMessage('error',"Не правильно заполнено");
                }
            });
        },  
        update() {
            let obj = {
                name: this.category_name,
                id: this.selectedId,
                type: this.$route.query.type
            };
            this.$axios.put(this.$API_URL + this.$API_VERSION + "category/"+this.selectedId,
                {
                    data: obj
                }, {
                headers: {
                    
                },
            })
            .then((response) => {
                this.openEditCategory = false;
                this.showMessage('success',response.data.message);
                this.getCategory();
                this.category_name = '';
            })
            .catch((error) => {
                if (error.response && error.response.status == 422) {
                    this.showMessage('error',"Не правильно заполнено");
                }
            });  
            
        }, 

  },
  mounted() {
        this.getCategory();
  },
  beforeMount() {
  },
  watch: {
    openCategory(val) {
        if(!val) {
            this.type=1;
        }
        this.getCategory();
    },
    options: {
      handler(val) {
        if (val.itemsPerPage < 0) {
          val.itemsPerPage = this.totalPage;
            this.$emit('fetchData',this.options);
        } else {
            this.$emit('fetchData',this.options);
        }
      },
    },

  },
};
</script>
