import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import vuetify from "./plugins/vuetify";
import store from "./store";
import router from "./router";
import 'vue-toast-notification/dist/theme-default.css'
import VueToast from 'vue-toast-notification'
import Vue2Editor from "vue2-editor";


// axios.interceptors.response.use(response => {
//     return response;
// }, error => {
//     if (error.response.status === 401) {
//         localStorage.removeItem('access_token');
//         window.location.href = "https://admin.turkic-inscriptions.crocos.kz";
//     }
//     return Promise.reject(error);
// });
Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
Vue.prototype.$API_VERSION = 'v1/';
Vue.prototype.$API_VERSION_2 = process.env.VUE_APP_API_VERSION_2;
Vue.prototype.$API_URL = 'https://api.turkic-inscriptions.crocos.kz/api/';
Vue.prototype.$API_MAIN = 'https://api.turkic-inscriptions.crocos.kz/';
Vue.use(VueToast);
Vue.use(Vue2Editor);

Vue.component('v-category', require('./components/Category.vue').default);

axios.defaults.headers.common['Authorization'] = 'Bearer' + ' ' + localStorage.getItem('access_token')
new Vue({
    store,
    vuetify,
    router,
    render: (h) => h(App),
}).$mount("#app")